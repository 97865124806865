/* eslint-disable react/prop-types */
import React from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import { Button, Box, TextField, FormControl, Typography, InputLabel } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DropzoneArea } from "mui-file-dropzone";
import { colors } from "../../styles/config";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/stylesheets/base.css";
import "../../styles/stylesheets/calendar.css";
import ErrorMessage from "./ErrorMessage";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
    minWidth: "100%"
  },
  specialInputLabel: {
    backgroundColor: colors.white,
    paddingLeft: "5px",
    paddingRight: "5px"
  }
}));

export default function OptInForm({ onSubmit, onCancel, onFileChange, values = {} }) {
  const { error, currentJob } = useSelector((state) => state.jobs);
  const notFoundError =
    error &&
    error.error &&
    error.error.toLowerCase().includes("job") &&
    error.error.includes("not found");

  const RELEVANT_EXPERIENCE_CHARACTER_LIMIT = 1000;
  const INTEREST_CHARACTER_LIMIT = 700;

  const initialValues = {
    roleInterest: "",
    locationInterest: "",
    earliestStartDate: "",
    idealStartDate: "",
    relevantExperience: "",
    base64Resume: "",
    banana: "empty"
  };

  const data = {
    ...initialValues,
    ...values
  };

  const validationSchema = yup.object().shape({
    roleInterest: yup.string().required("Role interest is required."),
    locationInterest: yup.string().required("Location intereste is required."),
    earliestStartDate: yup.string().required("Earliest start date is required."),
    idealStartDate: yup.string().required("Ideal start date is required."),
    relevantExperience: yup.string().required("Relevant experience is required."),
    base64Resume: yup.string().required("Resume is required."),
    banana: yup
      .string()
      .matches(/(empty)/)
      .required("Required")
  });

  const classes = useStyles();

  if (notFoundError) {
    return <Typography>{notFoundError}</Typography>;
  }

  return (
    <Formik
      initialValues={data}
      onSubmit={async (values, { resetForm }) => {
        await onSubmit(values);
        resetForm();
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        setFieldTouched,
        setFieldValue,
        touched,
        dirty,
        handleSubmit,
        resetForm
      }) => {
        return (
          <Box
            style={{ width: "100%" }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mx="auto"
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-around"
              mx={0}
              my={3}
              style={{ width: "100%" }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ minWidth: "unset", width: "50%", marginLeft: 0 }}
              >
                <InputLabel className={classes.specialInputLabel} shrink>
                  Earliest start date?
                </InputLabel>
                <DatePicker
                  placeholderText="MM/DD/YYYY"
                  selected={
                    (values.earliestStartDate && new Date(values.earliestStartDate)) || null
                  }
                  onChange={(val) => {
                    setFieldValue("earliestStartDate", val);
                  }}
                />
                <Box className="datepicker-notched-outline"></Box>
                {errors.earliestStartDate && touched.earliestStartDate ? (
                  <ErrorMessage message={"Please select a valid date."} />
                ) : null}
              </FormControl>

              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ minWidth: "unset", width: "50%", marginRight: 0 }}
              >
                <InputLabel className={classes.specialInputLabel} shrink>
                  Ideal start date?
                </InputLabel>
                <DatePicker
                  placeholderText="MM/DD/YYYY"
                  selected={(values.idealStartDate && new Date(values.idealStartDate)) || null}
                  onChange={(val) => {
                    setFieldValue("idealStartDate", val);
                  }}
                />
                <Box className="datepicker-notched-outline"></Box>
                {errors.idealStartDate && touched.idealStartDate ? (
                  <ErrorMessage message={"Please select a valid date."} />
                ) : null}
              </FormControl>
            </Box>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                multiline
                rows={3}
                inputProps={{
                  maxLength: 350
                }}
                helperText={`${values.locationInterest.length}/${350}`}
                className={values.locationInterest.length / 350 === 1 ? "maxed-chars" : ""}
                variant="outlined"
                value={values.locationInterest}
                onChange={(event) => setFieldValue("locationInterest", event.target.value)}
                onBlur={() => {
                  setFieldTouched("locationInterest", true);
                }}
                label={`Why are you interested in ${currentJob.address.city}, ${currentJob.address.state}?`}
                placeholder={`Enter a few sentences why you want to be in ${currentJob.address.city}.`}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                multiline
                rows={6}
                inputProps={{
                  maxLength: INTEREST_CHARACTER_LIMIT
                }}
                helperText={`${values.roleInterest.length}/${INTEREST_CHARACTER_LIMIT}`}
                className={
                  values.roleInterest.length / INTEREST_CHARACTER_LIMIT === 1 ? "maxed-chars" : ""
                }
                variant="outlined"
                value={values.roleInterest}
                onChange={(event) => setFieldValue("roleInterest", event.target.value)}
                onBlur={() => {
                  setFieldTouched("roleInterest");
                }}
                label="Why are you interested in this opportunity?"
                placeholder="Enter a few sentences about your interest."
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
              {errors.roleInterest && touched.roleInterest ? (
                <ErrorMessage message={"Please enter a response for the above question."} />
              ) : null}
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                multiline
                rows={1}
                inputProps={{
                  maxLength: 80
                }}
                className={"hide"}
                variant="outlined"
                value={values.banana}
                onChange={(event) => setFieldValue("banana", event.target.value)}
                onBlur={() => {
                  setFieldTouched("banana", true);
                }}
                label="Do you like bananas?"
                placeholder="Enter few sentences about why you like bananas."
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                multiline
                rows={7}
                inputProps={{
                  maxLength: RELEVANT_EXPERIENCE_CHARACTER_LIMIT
                }}
                helperText={`${values.relevantExperience.length}/${RELEVANT_EXPERIENCE_CHARACTER_LIMIT}`}
                className={
                  values.relevantExperience.length / RELEVANT_EXPERIENCE_CHARACTER_LIMIT === 1
                    ? "maxed-chars"
                    : ""
                }
                variant="outlined"
                value={values.relevantExperience}
                onChange={(event) => setFieldValue("relevantExperience", event.target.value)}
                onBlur={() => {
                  setFieldTouched("relevantExperience", true);
                }}
                label="What select work experience would you like to highlight for the hiring committee?"
                placeholder="Enter few sentences about your experience for the hiring committee to read."
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </FormControl>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ marginTop: 24 }}
            >
              <InputLabel className={classes.specialInputLabel} shrink>
                Resume Upload
              </InputLabel>
              <DropzoneArea
                Icon={() => {
                  return null;
                }}
                previewGridClasses={{ item: "MuiGrid-grid-xs-6" }}
                acceptedFiles={["application/pdf"]}
                filesLimit={1}
                showPreviewsInDropzone={true}
                showFileNames={true}
                onDelete={() => {
                  setFieldValue("base64Resume", "");
                  onFileChange();
                }}
                onChange={(files) => {
                  if (files && files.length) setFieldValue("base64Resume", "file added");
                  onFileChange(files);
                }}
                dropzoneText="Upload a PDF version of your resume (drag & drop or click here)"
              />
            </FormControl>

            <Box
              width={"100%"}
              display="flex"
              alignItems="flex-end"
              justifyContent="flex-end"
              my={1}
            >
              <Button
                variant="contained"
                color="primary"
                style={{ width: "200px" }}
                size="medium"
                disabled={!dirty || (dirty && errors && Object.keys(errors).length) ? true : false}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
            <Box
              width={"100%"}
              my={1}
              display="flex"
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                variant="default"
                color="secondary"
                style={{ width: "200px" }}
                size="medium"
                onClick={() => {
                  resetForm();
                  onCancel();
                }}
              >
                Cancel
              </Button>
            </Box>

            <Box my={2} width="100%" display="flex" justifyContent="start">
              <Typography variant="h5" width="100%">
                “By providing a telephone number and submitting this form you are consenting to be
                contacted by SMS text message. Message & data rates may apply. You can reply STOP to
                opt-out of further messaging.”
              </Typography>
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
}
